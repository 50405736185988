<template>
    <span v-if="hasChildren">
        <h1 v-if="node.type === 'h1'">
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </h1>
        <h2 v-else-if="node.type === 'h2'">
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </h2>
        <h3 v-else-if="node.type === 'h3'">
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </h3>
        <h4 v-else-if="node.type === 'h4'">
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </h4>
        <h5 v-else-if="node.type === 'h5'">
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </h5>
        <h6 v-else-if="node.type === 'h6'">
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </h6>
        <a
            v-else-if="node.type === 'link'"
            :href="node.url"
            target="_blank"
            aria-describedby="opens-in-new-window"
        >
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </a>
        <p v-else>
            <RichTextNode v-for="(node, index) in node.children" :key="index" :node="node" />
        </p>
    </span>
    <RichTextLeaf v-else :node="node" />
</template>

<script>
import { Text } from 'slate'
import RichTextLeaf from './RichTextLeaf.vue'

export default {
    name: 'RichTextNode',
    components: {
        RichTextLeaf,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasChildren() {
            return !Text.isText(this.node)
        },
    },
}
</script>
