<template>
    <div
        class="home"
        :class="{ isLoading: !!loading, dragging }"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
    >
        <div v-if="translationsError">
            <h1 class="error">{{ $t('error.couldNotGetTranslations') }}</h1>
        </div>
        <div v-else class="content" ref="content">
            <div class="card">
                <div class="upload-file" :aria-hidden="!!loading">
                    <div v-if="translationsError" class="title">
                        <h1>{{ $t('error.couldNotGetTranslations') }}</h1>
                    </div>
                    <div class="title">
                        <h1>{{ translations.title }}</h1>
                        <span aria-hidden="true">{{ translations.title }}</span>
                    </div>
                    <p class="intro">
                        {{ translations.intro }}
                    </p>
                    <div class="upload-container">
                        <div class="upload-container__item">
                            <input
                                ref="uploader"
                                class="hide-visually"
                                type="file"
                                name="pdf"
                                accept=".pdf"
                                aria-hidden="true"
                                tabindex="-1"
                                @change="handleChangeInput"
                            />
                            <div class="button-container">
                                <button
                                    class="button huge secondary upload-button"
                                    @click="handleClickButton"
                                    :tabindex="!!loading && '-1'"
                                >
                                    {{
                                        dragging
                                            ? $t('homepage.dragRelease')
                                            : $t('homepage.uploadFile')
                                    }}
                                </button>
                            </div>
                            <div
                                class="helper"
                                v-html="
                                    $t('homepage.dragFile', {
                                        sizeLimit: sizeLimitUpload / 1000000,
                                    })
                                "
                            ></div>
                            <div class="error-message" v-if="fileError">
                                {{ fileError }}
                            </div>
                        </div>
                        <div class="upload-container__item upload-container__item_separator">
                            {{ $t('common.or') }}
                        </div>
                        <div class="upload-container__item">
                            <div class="url-container">
                                <input
                                    type="text"
                                    @change="handleChangeUrl"
                                    class="url-input"
                                    :placeholder="$t('homepage.enterUrl')"
                                    :aria-label="$t('homepage.enterUrl')"
                                />
                                <button class="button huge secondary" @click="handleSubmitUrl">
                                    {{ $t('homepage.uploadUrl') }}
                                </button>
                            </div>
                            <div class="error-message" v-if="urlError">
                                {{ urlError }}
                            </div>
                        </div>
                    </div>
                    <div class="error-message" v-if="error">
                        {{ error }}
                    </div>
                </div>
                <div class="loading">
                    <h2 class="loading-title" aria-live="polite" v-show="loading">
                        {{ $t(`statusses.${status}`) }}...
                    </h2>
                    <div class="loading-bar">
                        <div
                            class="loading-bar-animate"
                            :style="{
                                width: statusses[status].width + '%',
                                transition: `width ${statusses[status].transitionDuration}s linear`,
                            }"
                        />
                    </div>
                    <div class="loading-description">
                        {{ progress }}
                    </div>
                </div>
            </div>
            <div class="alerts">
                <div class="alert">
                    <svg
                        aria-hidden="true"
                        width="20px"
                        height="20px"
                        viewBox="0 0 20 20"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <g
                            id="ontwerp"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g
                                id="home"
                                transform="translate(-272.000000, -453.000000)"
                                fill="currentColor"
                                fill-rule="nonzero"
                            >
                                <g id="Group-2" transform="translate(254.000000, 439.000000)">
                                    <g
                                        id="info_black_24dp"
                                        transform="translate(16.000000, 12.000000)"
                                    >
                                        <path
                                            d="M11,7 L13,7 L13,9 L11,9 L11,7 Z M11,11 L13,11 L13,17 L11,17 L11,11 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"
                                            id="Shape"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    {{ translations.alert }}
                </div>
            </div>
            <span class="hide-visually" id="opens-in-new-window">{{
                $t('common.opensInNewWindow')
            }}</span>
            <div class="row">
                <div class="col" v-for="(text, index) in translations.content" :key="index">
                    <RichText :nodes="text.richText" />
                </div>
            </div>
            <div class="about-button">
                <router-link to="/about" class="button large tertiary">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z" />
                    </svg>
                    {{ $t('buttons.learnMore') }}
                </router-link>
            </div>
            <h2 class="organisations-title">{{ $t('homepage.madePossibleBy') }}</h2>
            <div class="row organisations">
                <a href="https://pleio.nl/" target="_blank" aria-describedby="opens-in-new-window">
                    <img
                        :src="require('../assets/images/logo_Pleio.svg')"
                        :alt="$t('homepage.pleioLogoAlt')"
                    />
                </a>
                <a
                    href="https://forumstandaardisatie.nl/"
                    target="_blank"
                    aria-describedby="opens-in-new-window"
                >
                    <img
                        :src="require('../assets/images/logo_ForumStandaardisatie.svg')"
                        :alt="$t('homepage.forumStandaardisatieLogoAlt')"
                    />
                </a>
                <a
                    href="https://www.duallab.com/"
                    target="_blank"
                    aria-describedby="opens-in-new-window"
                >
                    <img
                        :src="require('../assets/images/logo_DualLab.svg')"
                        :alt="$t('homepage.dualLabLogoAlt')"
                    />
                </a>
                <a href="https://swink.nl/" target="_blank" aria-describedby="opens-in-new-window">
                    <img
                        :src="require('../assets/images/logo_Swink.svg')"
                        :alt="$t('homepage.swinkLogoAlt')"
                    />
                </a>
                <a
                    href="https://www.sidnfonds.nl/"
                    target="_blank"
                    aria-describedby="opens-in-new-window"
                >
                    <img
                        :src="require('../assets/images/logo_SIDNFonds.svg')"
                        :alt="$t('homepage.sidnFondsLogoAlt')"
                    />
                </a>
                <a
                    href="https://openpreservation.org/"
                    target="_blank"
                    aria-describedby="opens-in-new-window"
                >
                    <img
                        :src="require('../assets/images/logo_OpenPreservationFoundation.svg')"
                        :alt="$t('homepage.openPreservationFoundationLogoAlt')"
                    />
                </a>
                <a
                    href="https://www.gehandicaptenvalkenswaard.nl/"
                    target="_blank"
                    aria-describedby="opens-in-new-window"
                >
                    <img
                        :src="
                            require('../assets/images/logo_PlatformGehandicaptenValkenswaard.svg')
                        "
                        :alt="$t('homepage.platformGehandicaptenValkenswaardLogoAlt')"
                    />
                </a>
                <a
                    href="https://documenten-en-toegankelijkheid.nl/"
                    target="_blank"
                    aria-describedby="opens-in-new-window"
                >
                    <img
                        :src="require('../assets/images/logo_DocumentenEnToegankelijkheid.svg')"
                        :alt="$t('homepage.documentenEnToegankelijkheidLogoAlt')"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router'
import { ErrorType } from '../constants/ErrorType'
import * as uploadSizeLimit from '@/uploadSizeLimit.json'
import RichText from '../components/RichText/RichText.vue'

export default {
    name: 'App',
    components: {
        RichText,
    },
    data() {
        return {
            file: null,
            url: '',
            loading: '',
            timer: '',
            timesRun: 0,
            dragging: false,
            progress: '',
            statusses: {
                uploadingPdf: { width: 0, transitionDuration: 0 },
                startScanning: { width: 10, transitionDuration: 1 },
                created: { width: 20, transitionDuration: 1 },
                waiting: { width: 30, transitionDuration: 1 },
                processing: { width: 90, transitionDuration: 6 },
                cancelled: { width: 100, transitionDuration: 1 },
                finished: { width: 100, transitionDuration: 1 },
            },
            status: 'uploadingPdf',
            error: '',
            fileError: '',
            urlError: '',
            sizeLimitUpload: uploadSizeLimit.limit,
            translations: {},
            translationsError: false,
        }
    },
    created() {
        this.fetchTranslations()
    },
    destroyed() {
        clearInterval(this.timer)
    },
    watch: {
        '$i18n.locale': {
            handler: function() {
                this.fetchTranslations()
            },
        },
    },
    methods: {
        async fetchTranslations() {
            /* Below code is temporary. When the CMS is permanently available, the code below can be removed */
            if (config.VUE_APP_CMS_BASE_URL === undefined) {
                this.translations = {
                    title: this.$i18n.t('homepage.PDFchecker'),
                    intro: this.$i18n.t('homepage.intro'),
                    alert: this.$i18n.t('homepage.alertMessage'),
                    content: [
                        {
                            richText: [
                                {
                                    type: 'h2',
                                    children: [
                                        {
                                            text: this.$i18n.t('homepage.appFunctionality'),
                                        },
                                    ],
                                },
                                {
                                    children: [
                                        {
                                            text: `${this.$i18n.t(
                                                'homepage.functionalityDescription'
                                            )} `,
                                        },
                                        {
                                            url: 'https://www.w3.org/Translations/WCAG21-nl/',
                                            type: 'link',
                                            children: [
                                                {
                                                    text: this.$i18n.t('homepage.WCAG21Criteria'),
                                                },
                                            ],
                                        },
                                        {
                                            text: ` ${this.$i18n.t('common.and')} `,
                                        },
                                        {
                                            url: 'https://www.iso.org/standard/64599.html',
                                            type: 'link',
                                            children: [
                                                {
                                                    text: this.$i18n.t('homepage.PDFAUEssentials'),
                                                },
                                            ],
                                        },
                                        {
                                            text: `. ${this.$i18n.t('homepage.appStatus')}`,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            richText: [
                                {
                                    type: 'h2',
                                    children: [
                                        {
                                            text: this.$i18n.t('homepage.howToUse'),
                                        },
                                    ],
                                },
                                {
                                    children: [
                                        {
                                            text: this.$i18n.t('homepage.howToUseDescription'),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    error: {
                        couldNotGetTranslations: this.$t('error.couldNotGetTranslations'),
                        oops: this.$t('error.oops'),
                        invalid: this.$t('error.invalid'),
                        sizeLimit: this.$t('error.sizeLimit'),
                        noUrl: this.$t('error.noUrl'),
                    },
                }
                return
            }
            /* Above code is temporary. When the CMS is permanently available, the code above can be removed and the code below can be uncommented. */
            /*
            if (config.VUE_APP_CMS_BASE_URL === undefined) {
                this.translationsError = true
            }
            */
            try {
                const homePageTranslationsResponse = await fetch(
                    `${config.VUE_APP_CMS_BASE_URL}/api/globals/home-page?locale=${this.$i18n
                        .locale || 'nl'}`
                )

                const homePageTranslations = await homePageTranslationsResponse.json()

                this.translations = {
                    ...homePageTranslations,
                    error: {
                        couldNotGetTranslations: this.$t('error.couldNotGetTranslations'),
                        oops: this.$t('error.oops'),
                        invalid: this.$t('error.invalid'),
                        sizeLimit: this.$t('error.sizeLimit'),
                        noUrl: this.$t('error.noUrl'),
                    },
                }
            } catch (error) {
                this.translationsError = true
            }
        },
        handleClickButton() {
            this.$refs.uploader.focus()
            this.$refs.uploader.click()
        },
        handleChangeInput(evt) {
            this.error = ''
            this.urlError = ''
            this.fileError = ''
            if (evt.target.files[0].size > this.sizeLimitUpload) {
                this.fileError = this.translations.error.sizeLimit
            } else {
                this.getFileIdForUpload(evt.target.files[0])
            }
        },
        handleChangeUrl(evt) {
            this.error = ''
            this.fileError = ''
            this.urlError = ''
            this.url = evt.target.value
        },
        handleSubmitUrl() {
            this.error = ''
            this.fileError = ''
            this.urlError = ''
            if (this.url === '') {
                this.urlError = this.translations.error.noUrl
            } else {
                this.getFileIdForUrl(this.url)
            }
        },
        remove(i) {
            this.filelist.splice(i, 1)
        },
        dragover(evt) {
            evt.stopPropagation()
            evt.preventDefault()
            if (!this.dragging) this.dragging = true
        },
        dragleave(evt) {
            if (!this.$refs.content.contains(evt.target)) {
                evt.stopPropagation()
                evt.preventDefault()
                if (this.dragging) this.dragging = false
            }
        },
        drop(evt) {
            this.error = ''
            this.fileError = ''
            this.urlError = ''
            evt.stopPropagation()
            evt.preventDefault()
            if (this.dragging) this.dragging = false
            const file = evt.dataTransfer.files[0]
            if (file.size > this.sizeLimitUpload) {
                if (this.dragging) this.dragging = false
                this.loading = ''
                this.fileError = this.translations.error.sizeLimit
            } else if (file.type === 'application/pdf') {
                this.getFileIdForUpload(file)
            } else {
                if (this.dragging) this.dragging = false
                this.loading = ''
                this.fileError = this.translations.error.invalid
            }
        },
        refetchJobInfo() {
            this.timesRun += 1
            this.getJobInfo(this.jobId)
        },
        async getFileIdForUpload(file) {
            this.status = 'uploadingPdf'
            this.loading = 'uploaden'
            let formData = new FormData()
            formData.append('file', file)
            this.file = file
            try {
                const result = await fetch('/api/files', {
                    method: 'POST',
                    body: formData,
                })
                const data = await result.json()
                this.createJob(data.id)
            } catch (e) {
                this.loading = ''
                console.error(e)
            }
        },
        async getFileIdForUrl(url) {
            this.status = 'uploadingPdf'
            this.loading = 'uploaden'
            try {
                const result = await fetch(`/api/files/url?url=${url}`, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                })
                const data = await result.json()
                this.createJob(data.id)
            } catch (e) {
                this.loading = ''
                console.error(e)
            }
        },
        async createJob(fileId) {
            this.status = 'startScanning'
            this.loading = 'scannen'
            try {
                const result = await fetch('/api/jobs', {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        profile: 'WCAG_2_2_COMPLETE',
                        tasks: [
                            {
                                fileId,
                            },
                        ],
                    }),
                })
                const data = await result.json()
                fetch(`/api/jobs/${data.id}/execution`, {
                    method: 'POST',
                })
                this.jobId = data.id
                this.timer = setInterval(this.refetchJobInfo, 1000)
            } catch (e) {
                this.loading = ''
                console.error(e)
            }
        },
        async getJobInfo(jobId) {
            try {
                const result = await fetch(`/api/jobs/${jobId}`)
                const data = await result.json()
                this.status = data.status.toLowerCase()
                this.progress = data.progress
                if (data.status === 'FINISHED') {
                    clearInterval(this.timer)
                    this.timesRun = 0
                    const task = data.tasks[0]
                    if (task.status === 'FINISHED') {
                        this.downloadFile(data.tasks[0].validationResultId, data.tasks[0].fileId)
                    }
                    if (task.status === 'ERROR') {
                        console.error('Request failed', `${task.errorType}: ${task.errorMessage}`)
                        switch (task.errorType) {
                            case ErrorType.TASK_PROCESSING_LIMIT_ERROR:
                                this.loading = ''
                                this.error = this.translations.error.oops
                                break
                            case ErrorType.PROCESSING_INTERNAL_ERROR:
                            default:
                                this.loading = ''
                                this.error = this.translations.error.oops
                        }
                    }
                } else {
                    if (this.timesRun === 60) {
                        this.loading = ''
                        this.error = this.translations.error.oops
                        clearInterval(this.timer)
                        this.timesRun = 0
                    }
                }
            } catch (e) {
                this.loading = ''
                console.error(e)
            }
        },
        async downloadFile(validationResultId, fileId) {
            try {
                if (!this.file) {
                    const resultFile = await fetch(`/api/files/${fileId}`)
                    const arrayBuffer = await resultFile.arrayBuffer()
                    this.file = { data: new Uint8Array(arrayBuffer) }
                }

                const resultData = await fetch(`/api/files/${validationResultId}`)
                const data = await resultData.json()

                this.showResults(data)
            } catch (e) {
                this.loading = ''
                console.error(e)
            }
        },
        showResults(data) {
            router.push({
                name: 'results',
                params: { file: this.file, ruleSummaries: data.details.ruleSummaries },
            })
        },
    },
}
</script>

<style>
.home {
    min-height: 100vh;
    padding: 20vh var(--size-small) var(--size-small);
    box-sizing: border-box;
}

.error {
    text-align: center;
    font-size: var(--font-size-title-large);
    font-weight: var(--font-weight-bold);
    color: var(--color-alert);
    max-width: 600px;
    margin: 0 auto;
}

.content {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}

.card {
    position: relative;
    text-align: center;
    margin-bottom: 64px;
}

.upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.25s ease, opacity 0.15s ease;
}

.home.isLoading .upload-file {
    opacity: 0;
    pointer-events: none;
    transform: translateY(8px);
}

.upload-file .title {
    position: relative;
    margin-bottom: 10px;
    font-size: var(--font-size-title-large);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
}

.upload-file .title span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    overflow: hidden;
    color: #137eff;
    user-select: none;
    pointer-events: none;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    transition: transform 0.25s ease, opacity 0.15s ease;
    transform: translateY(0);
}

.home:not(.isLoading) .loading {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-8px);
}

.loading-title {
    margin-bottom: 14px;
    font-size: var(--font-size-title-small);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
}

.loading-bar {
    position: relative;
    width: 100%;
    max-width: 200px;
    height: 12px;
    background: var(--color-grey-20);
    box-shadow: inset 0 0 1px 1px var(--color-grey-30);
    border-radius: var(--radius-normal);
    overflow: hidden;
}

.loading-bar-animate {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--color-primary);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.loading-bar-animate:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45%;
    background: #137eff;
}

.loading-description {
    height: 20px;
    margin-top: 16px;
    font-size: var(--font-size-small);
}

.intro {
    font-size: var(--font-size-large);
    margin-bottom: 32px;
}

.upload-button {
    position: relative;
    margin-bottom: 10px;
}

.home.dragging .upload-button {
    background: var(--color-primary);
    color: white;
}

.home.dragging * {
    pointer-events: none;
}

.helper {
    font-size: var(--font-size-small);
    color: var(--color-text-grey);
}

.error-message {
    margin-top: 20px;
    font-size: var(--font-size-small);
    color: var(--color-alert);
}

.alerts {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.alert {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: var(--color-secondary-hover);
    border-radius: var(--radius-normal);
    color: #006bee;
    font-size: var(--font-size-normal);
}

.alert svg {
    flex-shrink: 0;
    margin-right: 8px;
    color: var(--color-primary);
}

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.col {
    width: 100%;
    max-width: 380px;
}

h2 {
    font-size: var(--font-size-title-small);
    font-weight: var(--font-weight-bold);
}

.col h2 {
    margin: 0 0 16px;
}

.col p {
    margin: 0 0 56px;
    font-size: var(--font-size-large);
}

.about-button {
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
}

.organisations-title {
    text-align: center;
    margin: 0 0 16px;
}

.organisations {
    align-items: center;
}

.organisations h2 {
    width: 100%;
    text-align: center;
    margin: 0 0 16px;
}

.organisations > * {
    margin: 16px;
}

.upload-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
}

@media (min-width: 768px) {
    .upload-container {
        flex-direction: row;
        align-items: flex-start;
    }
}

.upload-container__item_separator {
    font-size: var(--font-size-normal);
    margin-top: 8px;
}

.url-container {
    display: flex;
    gap: 8px;
}

.url-input {
    font-family: 'Rijksoverheid Sans', sans-serif;
    border-radius: var(--radius-normal);
    font-size: var(--font-size-normal);
    padding: 8px;
    border: 1px solid;
    width: 100%;
}
</style>
