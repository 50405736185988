<template>
    <code v-if="isCode" class="code"><TextWithLines :text="textWithLines"/></code>
    <span v-else-if="isStyledLeaf"><TextWithLines :text="textWithLines"/></span>
    <span v-else>
        <b v-if="isBold"><TextWithLines :text="textWithLines"/></b>
        <span v-else><TextWithLines :text="textWithLines"/></span>
    </span>
</template>

<script>
import TextWithLines from './TextWithLines.vue'

export default {
    name: 'RichTextLeaf',
    components: {
        TextWithLines,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isBold() {
            return this.node.bold
        },
        isCode() {
            return this.node.code
        },
        isStyledLeaf() {
            return this.className !== undefined
        },
        textWithLines() {
            if (!this.node.text) {
                return []
            } else {
                return this.node.text.split(/[\n\u2028]/)
            }
        },
    },
}
</script>
