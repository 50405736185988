import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'PDFchecker'
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: DEFAULT_TITLE },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { title: 'Over PDFchecker' },
    },
    {
        path: '/results',
        name: 'results',
        props: true,
        component: () => import(/* webpackChunkName: "results" */ '../views/Results.vue'),
        meta: { title: 'Resultaten van de toegankelijkheidsscan - PDFchecker' },
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "results" */ '../views/NotFound.vue'),
        meta: { title: 'Pagina niet gevonden - PDFchecker' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
})

router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
})

export default router
