<template>
    <div class="language-selector">
        <div
            class="selected-option"
            @click="toggleDropdown"
            :class="{ active: isDropdownOpen }"
            tabindex="0"
        >
            <span
                class="language-switcher__flag"
                :class="['language-switcher--' + selectedLanguage]"
            ></span>
            {{ getFullCountryName(selectedLanguage) }}
        </div>

        <transition name="fade">
            <ul v-if="isDropdownOpen" class="options-list">
                <li
                    v-for="(language, index) in languages"
                    :key="index"
                    @click="changeLanguage(language.code)"
                    :class="{
                        active: selectedLanguage === language.code,
                        ['language-switcher--' + language.code]: true,
                    }"
                >
                    {{ getFullCountryName(language.code) }}
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'LanguageSelector',
    props: ['selectedLanguage', 'availableLanguages'],
    data() {
        return {
            isDropdownOpen: false,
            languages: this.generateLanguages(),
        }
    },
    methods: {
        getFullCountryName(langCode) {
            switch (langCode) {
                case 'en':
                    return 'English'
                case 'de':
                    return 'Deutsch'
                case 'nl':
                    return 'Nederlands'
                default:
                    return langCode
            }
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen
        },
        changeLanguage(langCode) {
            this.$emit('language-change', langCode)
            this.isDropdownOpen = false
        },
        generateLanguages() {
            return this.availableLanguages.map(langCode => {
                return {
                    code: langCode,
                    name: this.$i18n.messages[langCode]?.name || langCode,
                }
            })
        },
    },
    watch: {
        availableLanguages: {
            immediate: true,
            handler() {
                this.languages = this.generateLanguages()
            },
        },
    },
}
</script>
<style scoped>
.options-list .language-switcher--en {
    position: relative;
}

.language-selector {
    position: fixed;
    right: 10px;
    top: 10px;
    color: var(--color-primary);
    cursor: pointer;
    font-size: var(--font-size-large);
    border: 1px solid var(--color-primary);
    min-width: 170px;
    background-color: white;
    border-radius: var(--radius-normal);
    box-sizing: border-box;
    z-index: 50;
}

.selected-option {
    padding: 8px 12px;
}

.options-list li {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--color-tooltip-dark);
}

.options-list li.active {
    color: var(--color-primary);
}
</style>
