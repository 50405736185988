<template>
    <div class="app">
        <LanguageSelector
            :selectedLanguage="currentLocale"
            @language-change="changeLocale"
            :available-languages="availableLocales"
        />
        <router-view />
    </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
    name: 'App',
    components: {
        LanguageSelector,
    },
    computed: {
        currentLocale() {
            return this.$i18n.locale
        },
        availableLocales() {
            return Object.keys(this.$i18n.messages)
        },
    },

    methods: {
        changeLocale(langCode) {
            this.$i18n.locale = langCode
        },
    },
}
</script>

<style>
@font-face {
    font-family: 'Rijksoverheid Sans';
    src: url('./assets/rijksoverheidsanswebtext-regular-webfont.woff2') format('woff2'),
        url('./assets/rijksoverheidsanswebtext-regular-webfont.woff') format('woff'),
        url('./assets/rijksoverheidsanswebtext-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rijksoverheid Sans';
    src: url('./assets/rijksoverheidsanswebtext-bold-webfont.woff2') format('woff2'),
        url('./assets/rijksoverheidsanswebtext-bold-webfont.woff') format('woff'),
        url('./assets/rijksoverheidsanswebtext-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.language-selector {
    position: relative;
}

.language-selector,
.language-selector li,
.language-selector {
    width: 140px;
    padding: 0;
}

.options-list .language-switcher--en {
    position: relative;
}

.language-switcher__flag::before,
.language-switcher--en::before,
.language-switcher--de::before,
.language-switcher--nl::before {
    content: '';
    display: inline-block;
    width: 20px;
    aspect-ratio: 3/2;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.language-switcher--en::before {
    background-image: url('./assets/en.svg');
}

.language-switcher--de::before {
    background-image: url('./assets/de.svg');
}

.language-switcher--nl::before {
    background-image: url('./assets/nl.svg');
}

:root {
    --color-primary: #0771f2;
    --color-primary-background: #0771f230;

    --color-primary-hover: #1b7ff8;
    --color-primary-active: #398ff9;

    --color-secondary-hover: #f3f8ff;
    --color-secondary-active: #deedff;

    --color-text-grey: rgba(0, 0, 0, 0.55);
    --color-icon-grey: rgba(0, 0, 0, 0.42);

    --color-hover: rgba(0, 0, 0, 0.03);
    --color-active: rgba(0, 0, 0, 0.06);

    --color-grey-10: #f5f5f5;
    --color-grey-20: #eaeaea;
    --color-grey-30: #dddddd;
    --color-grey-40: #bfbfbf;
    --color-grey-50: #949494;

    --color-tooltip-dark: #222222;

    --color-alert: #eb0000;

    --font-size-tiny: 1.2rem;
    --font-size-small: 1.4rem;
    --font-size-normal: 1.6rem;
    --font-size-large: 1.8rem;

    --font-size-title-small: 2rem;
    --font-size-title-normal: 2.4rem;
    --font-size-title-large: 3.2rem;

    --font-weight-normal: normal;
    --font-weight-bold: bold;

    --radius-small: 3px;
    --radius-normal: 6px;

    --shadow-normal: 0 0 1px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.15);

    --size-small: 24px;
    --size-normal: 32px;
    --size-large: 40px;
    --size-huge: 44px;
}

/* @media (min-width: 576px) {
    :root {
        --width-detail: 350px;
    }
} */

html {
    font-family: 'Rijksoverheid Sans', sans-serif;
    font-size: 62.5%;
    line-height: 1.5;
    letter-spacing: -0.005em;
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
}

h1,
h2 {
    margin: 0;
}

p {
    margin: 0;
}

p a {
    color: var(--color-primary);
    text-decoration: underline;
}

h1 {
    font: inherit;
}

button {
    margin: 0;
    padding: 0;
    border: none;
    color: inherit;
    background: transparent;
    font: inherit;
    letter-spacing: inherit;
    text-align: left;
}

input::placeholder {
    color: var(--color-text-grey);
}

button:not([disabled]) {
    cursor: pointer;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.hide-visually {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: transform 0.1s, opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
    transform: translateY(8px);
    opacity: 0;
}

.button {
    display: inline-flex;
    align-items: center;
    border: 2px solid transparent;
    border-radius: var(--radius-normal);
    font-family: inherit;
    font-weight: var(--font-weight-bold);
    letter-spacing: -0.015em;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
}

.button.normal {
    height: var(--size-normal);
    padding: 0 8px;
    font-size: var(--font-size-small);
}
.button.normal svg {
    margin-right: 4px;
}
.button.large {
    height: var(--size-large);
    padding: 0 12px;
    font-size: var(--font-size-normal);
}
.button.large svg {
    margin-left: -2px;
    margin-right: 6px;
    margin-top: -2px;
}
.button.huge {
    height: var(--size-huge);
    padding: 0 20px;
    font-size: var(--font-size-large);
}

.button.primary {
    background: var(--color-primary);
    color: white;
}
.button.primary:hover {
    background: var(--color-primary-hover);
}
.button.primary:active {
    background: var(--color-primary-active);
}

.button.secondary {
    background: white;
    color: var(--color-primary);
    border-color: var(--color-primary);
}
.button.secondary:hover {
    background: var(--color-secondary-hover);
}
.button.secondary:active {
    background: var(--color-secondary-active);
}

.button.tertiary {
    background: white;
    color: var(--color-primary);
}
.button.tertiary:hover {
    background: var(--color-secondary-hover);
}
.button.tertiary:active {
    background: var(--color-secondary-active);
}

.vue-splitter-container {
    width: 100vw !important;
    height: 100vh !important;
    overflow-y: hidden;
}

.splitter-pane-resizer.vertical {
    width: 2px !important;
    margin-left: -1px !important;
    background: var(--color-grey-20) !important;
    border: none !important;
    opacity: 1 !important;
    z-index: 1 !important;
}

.splitter-pane-resizer:after {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 12px;
    height: 100%;
}

.splitter-pane-resizer:before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 4px;
    height: 100%;
    background: var(--color-primary);
    opacity: 0;
    transition: opacity 0.1s ease;
}

.splitter-pane-resizer.vertical:hover:before {
    opacity: 1 !important;
}

.splitter-pane-resizer.vertical:active:before {
    transition: none !important;
}

.splitter-pane {
    box-sizing: border-box !important;
}

.splitter-paneL {
    padding-right: 0 !important;
    display: flex !important;
    background: var(--color-grey-10) !important;
}

.splitter-paneL > * {
    width: 100% !important;
}

.splitter-paneR {
    padding-left: 0 !important;
}

.pdf-document {
    padding: 56px 0 !important;
}

.pdf-page {
    margin-top: 0 !important;
    box-shadow: 0 0 0 2px var(--color-grey-20) !important;
}

.pdf-page:not(:last-child) {
    margin-bottom: 40px !important;
}

.pdf-bbox {
    border-width: 3px !important;
    border-color: var(--color-alert) !important;
}

.pdf-bbox_selected {
    border-color: var(--color-primary) !important;
    background-color: var(--color-primary-background) !important;
}

.pdf-bbox_selected::before {
    content: '>';
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translate(-100%, -50%);
    background-color: var(--color-primary);
    color: white;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    box-shadow: var(--shadow-normal);
}
</style>
