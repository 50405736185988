<template>
    <span>
        <RichTextNode v-for="(node, index) in nodes" :key="index" :node="node" />
    </span>
</template>

<script>
import RichTextNode from './RichTextNode.vue'

/**
 * This component renders out rich text coming from the Payload CMS.
 * If the rich text contains links, make sure to add a span with the
 * id='opens-in-new-window' and hide it visually with a textual
 * description for accessibility purposes. e.g.:
 * <span class="hide-visually" id="opens-in-new-window">Opens in a new window</span>
 **/
export default {
    name: 'RichText',
    components: {
        RichTextNode,
    },
    props: {
        nodes: Array,
    },
}
</script>
