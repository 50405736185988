<template>
    <br v-if="text.length === 0" />
    <span v-else>
        <span v-for="(line, index) in text">
            <span>{{ line }}</span>
            <br v-if="index + 2 <= text.length" />
        </span>
    </span>
</template>

<script>
export default {
    name: 'TextWithLines',
    props: {
        text: Array,
    },
}
</script>
